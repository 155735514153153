//import {normalize} from 'normalizr';

import { createReducer } from "../../store/utils";
import * as actionTypes from "./action-types";
import { APP_CLEAR_STORE } from "../authorization/action-types";

const initialState = {
	passwordRequest: false,
	isResetPassord: false,
	loadedDocument: true,
	countries: [],
	loading_registered: true,
	loading_validate_phone: true,
	btn_spinner: true,
	hasAccount: false,
	offerInProgress: { hasOffer: false },
	validate_email_loading: false,
	isCreateAccount: false,
};

export default createReducer(initialState, {
	[APP_CLEAR_STORE]: () => ({ ...initialState }),
	[actionTypes.REGISTER_BTN_LOADER]: (state) => ({ ...state, loading_registered: false }),
	[actionTypes.BTN_SPINNER]: (state) => ({ ...state, btn_spinner: false }),
	[actionTypes.CREATE_EXTERNAL_USER]: (state, action) => ({ ...state, ...action.payload, loading_registered: true }),
	[actionTypes.RESET_PASSWORD]: (state, action) => ({ ...state, ...action.payload }),
	[actionTypes.REQUEST_RESET_PASSWORD]: (state, action) => ({ ...state, ...action.payload }),
	[actionTypes.REQUEST_RESET_PASSWORD_WELCOME]: (state, action) => ({ ...state, ...action.payload }),
	[actionTypes.UPDATE_REGISTER_STATE]: (state, action) => ({ ...state, ...action.payload.object }),
	[actionTypes.VALIDATE_PHONE_REQUEST]: (state, action) => ({ ...state, ...action.payload }),
	[actionTypes.VALIDATE_PHONE]: (state, action) => ({ ...state, ...action.payload }),
	[actionTypes.REGISTER_USER_ACCOUNT]: (state, action) => ({ ...state, ...action.payload }),
	[actionTypes.GET_COUNTRIES]: (state, action) => ({ ...state, countries: action.payload }),
	[actionTypes.LOADING_VALIDATE_PHONE]: (state) => ({ ...state, loading_validate_phone: false }),
	[actionTypes.REGISTRATION_VALIDATE_PHONE_REQUEST]: (state) => ({ ...state, loading_validate_phone: true }),
	[actionTypes.REGISTRATION_VALIDATE_PHONE]: (state) => ({ ...state, loading_validate_phone: true }),
	[actionTypes.CREATE_ACCOUNT]: (state, action) => ({ ...state, ...action.payload, btn_spinner: true }),
	[actionTypes.VALIDATE_EMAIL_LOADING]: (state) => ({ ...state, validate_email_loading: true }),
	[actionTypes.VALIDATE_EMAIL]: (state) => ({ ...state, validate_email_loading: false }),
});
